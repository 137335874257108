import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { array, bool, object, string } from "yup";
import { strings } from "../../Common/String";
import { Form } from "../../Component/Form";
import { Input } from "../../Component/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { Email } from "../PopUps/Email";
import { RadioGroup } from "../../Component/Form/RadioGroup";
import RadioGroupOptions from "../../Common/RadioGroupOptions";
import { Dropdown } from "../../Component/Form/Dropdown";
import { MasterService } from "../../Services/MasterService";
import { AppContext } from "../../Context/AppContext";
import { LeadService } from "../../Services/LeadService";
import { Mobile } from "../PopUps/Mobile";
import { Address } from "../PopUps/Address";
import { Checkbox } from "../../Component/Form/Checkbox";
import { SingleCheckbox } from "../../Component/SingleCheckbox";
import "../../Common/css/summary.css";
import SegmentSlab from "./SegmentSlab";
import { BankDetails } from "../PopUps/BankDetails";
import { Nominee } from "../PopUps/Nominee";
import { getFullName, getPath, mask, maskEmail } from "../../Common/utils";
import { isEmpty, trim } from "lodash";
import { useHistory } from "react-router-dom";
import IconButton from "../../Common/IconButton";
import Button from "../../Component/Button";
import LayoutWrapper from "../../Common/LayoutWrapper";
import { SummaryProvider } from "./context";
import moment from "moment";
import { format_without_time } from "../../Common/Constants";

const SummaryPage = () => {
  const { setLoading, setHasChanges, showConfirmDialog, closeConfirmDialog } =
    useContext(AppContext);

  const [segments, setSegments] = useState([]);
  const [showMobile, setShowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showNominee, setShowNominee] = useState(false);
  const [formData, setFormData] = useState({});
  const [ddIncome, setDDIncome] = useState([]);
  const [ddOccupation, setDDOccupation] = useState([]);
  const [ddEducation, setDDEducation] = useState([]);
  const [total, setTotal] = useState(0);
  const [showTNC, setShowTNC] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);
  const [showRevert, setShowRevert] = useState({
    mobile: false,
    email: false,
    bank: false,
    nominee: false,
    segment: false,
    current_address: false,
  });
  const [showFields, setShowFields] = useState([]);
  const [enableFields, setEnableFields] = useState([]);
  const [apiMessage, setApiMessage] = useState("");
  const history = useHistory();

  const schema = object().shape({
    gender: string().test("required", strings.rekyc_error, (value) => {
      return !formData.gender ? !isEmpty(value) : true;
    }),
    marital_status: string().test("required", strings.rekyc_error, (value) => {
      return !formData.marital_status ? !isEmpty(value) : true;
    }),
    dob: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.dob ? !isEmpty(value) : true;
      })
      .test("adult_check", strings.rekyc_minor_error, (value) => {
        return !formData.dob
          ? moment(value).isBefore(moment().subtract(18, "years"))
          : true;
      }),
    annual_income: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.annual_income ? !isEmpty(value) : true;
      }),
    occupation: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.occupation ? !isEmpty(value) : true;
      }),
    education: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.education ? !isEmpty(value) : true;
      }),
    mother_name: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.mother_name ? !isEmpty(trim(value)) : true;
      }),
    father_name: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.father_name ? !isEmpty(trim(value)) : true;
      }),
    aadhaar: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return !formData.aadhaar ? !isEmpty(trim(value)) : true;
      }),
    segments: array().of(
      object().shape({
        type: string(),
        label: string(),
        codes: array().of(
          object().shape({
            code: string(),
            label: string(),
            checked: bool(),
            fixed: bool(),
          })
        ),
      })
    ),
    segmentConfirmaton: bool().test(
      "required",
      strings.rekyc_error,
      (value) => {
        return showTNC ? value : true;
      }
    ),
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      gender: "",
      marital_status: "",
      dob: "",
      annual_income: "",
      occupation: "",
      education: "",
      mother_name: "",
      father_name: "",
      aadhaar: "",
      segments: [],
    },
    resolver,
  });
  const watchSegmentConfirmation = registerForm.watch("segmentConfirmaton");

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    Promise.all([
      MasterService.getSegments(),
      MasterService.getDropdown("annual_income"),
      MasterService.getDropdown("education"),
      MasterService.getDropdown("occupation"),
      LeadService.getRekycSummary(),
    ])
      .then((datas) => {
        const getGender = (gender) => {
          switch (gender) {
            case "M":
              return RadioGroupOptions.GENDER[0];
            case "F":
              return RadioGroupOptions.GENDER[1];
            case "O":
              return RadioGroupOptions.GENDER[2];
            default:
              return "";
          }
        };
        const getMaritalStatus = (status) => {
          switch (status) {
            case "S":
              return RadioGroupOptions.MARITAL_STATUS[0];
            case "M":
              return RadioGroupOptions.MARITAL_STATUS[1];
            case "O":
              return RadioGroupOptions.MARITAL_STATUS[2];
            default:
              return "";
          }
        };
        let segments = {};
        if (datas[4] && datas[4].success && datas[0].segments) {
          segments = {};
          if (datas[4]) {
            datas[4].segment.forEach((s) => {
              segments[s.segment_group_code] = s.selected_options;
            });
          }
          const disabledSegments = datas[4].disabled_segments;
          datas[0].segments = datas[0].segments.map((s) => {
            s.options = s.options.filter(
              (e) => !disabledSegments.includes(e.code)
            );
            s.defaultChecked = segments[s.title_code] || [];
            return s;
          });
          setSegments(datas[0].segments);
        }
        if (datas[1] && datas[1].annual_income) {
          setDDIncome(datas[1].annual_income);
        }
        if (datas[2] && datas[2].education) {
          setDDEducation(datas[2].education);
        }
        if (datas[3] && datas[3].occupation) {
          setDDOccupation(datas[3].occupation);
        }
        if (datas[4]) {
          let formData = {};
          const data = datas[4];
          if (data.success) {
            formData.name = getFullName(
              data.first_name,
              data.middle_name,
              data.last_name
            );
            formData.mobile = data.mobile;
            formData.email = data.email;
            formData.gender = data.gender ? getGender(data.gender) : "";
            formData.marital_status = data.marital_status
              ? getMaritalStatus(data.marital_status)
              : "";
            formData.dob = data.dob || "";
            const incomeDef = datas[1].annual_income.find(
              (d) => d.is_default === true
            );
            formData.annual_income =
              data.annual_income ||
              incomeDef?.value ||
              datas[1].annual_income[0].value;
            const occupationDef = datas[3].occupation.find(
              (d) => d.is_default === true
            );
            formData.occupation =
              data.occupation ||
              occupationDef?.value ||
              datas[3].occupation[0].value;
            const educationDef = datas[2].education.find(
              (d) => d.is_default === true
            );
            formData.education =
              data.education ||
              educationDef?.value ||
              datas[2].education[0].value;
            formData.mother_name = data.mother_name || "";
            formData.father_name = data.father_name || "";
            formData.aadhaar = data.aadhaar ? data.aadhaar.slice(-4) : "";
            formData.pan = data.pan;
            formData.permanent_addr = data.permanent_addr;
            formData.permanent_addr1 = data.permanent_addr1;
            formData.permanent_addr2 = data.permanent_addr2;
            formData.permanent_addr3 = data.permanent_addr3;
            formData.permanent_pincode = data.permanent_pincode;
            formData.permanent_city = data.permanent_city;
            formData.permanent_state = data.permanent_state;
            formData.current_addr = data.current_addr;
            formData.maxAllowedNominees = data.max_allowed_nominees;
            formData.nominee = data.nominee ? "Yes" : "No";
            formData.nominee_name =
              data.nominee && !isEmpty(data.nominees)
                ? data.nominees[0].name
                : "";
            formData.banks = data.banks;
            formData.maxAllowedBanks = data.max_allowed_banks;
            setShowFields(data.show_fields);
            setEnableFields(data.enable_fields);
            setShowRevert({
              mobile: data.mobile_modified,
              email: data.email_modified,
              bank: data.bank_modified,
              nominee: data.nominee_modified,
              segment: data.segment_modified,
              current_address: data.current_address_modified,
            });
          }
          registerForm.reset({
            ...formData,
            segments: datas[0].segments.map((segment) => ({
              type: segment.title_code,
              label: segment.title_label,
              codes: segment.options.map((option) => ({
                code: option.code,
                label: option.label,
                checked: segment.defaultChecked.includes(option.code),
                fixed: segment.defaultChecked.includes(option.code),
              })),
            })),
          });
          setFormData((oldData) => ({ ...oldData, ...formData }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const onRevert = (type) => () => {
    showConfirmDialog(
      strings.revert_msg,
      undefined,
      () => {
        setLoading(true);
        LeadService.rekycRevertAction(type)
          .then((data) => {
            if (data.success) {
              revertSection(type);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        closeConfirmDialog();
      },
      strings.yes,
      strings.no
    );
  };
  const revertSection = (type) => {
    LeadService.getRekycSummary()
      .then((data) => {
        if (data.success) {
          setShowRevert({
            mobile: data.mobile_modified,
            email: data.email_modified,
            bank: data.bank_modified,
            nominee: data.nominee_modified,
            segment: data.segment_modified,
            current_address: data.current_address_modified,
          });
        }
        if (type === "mobile") {
          setFormData((formData) => ({ ...formData, mobile: data.mobile }));
        } else if (type === "email") {
          setFormData((formData) => ({ ...formData, email: data.email }));
        } else if (type === "current_address") {
          setFormData((formData) => ({
            ...formData,
            current_addr: data.current_addr,
          }));
        } else if (type === "bank") {
          setFormData((formData) => ({ ...formData, banks: data.banks }));
        } else if (type === "segment") {
          let segmentsObj = {};
          if (data) {
            data.segment.forEach((s) => {
              segmentsObj[s.segment_group_code] = s.selected_options;
            });
          }
          const disabledSegments = data.disabled_segments;
          data.segments = segments.map((s) => {
            s.options = s.options.filter((e) => !disabledSegments.includes(e));
            s.defaultChecked = segmentsObj[s.title_code] || [];
            return s;
          });
          setSegments(data.segments);
          registerForm.setValue(
            "segments",
            data.segments.map((segment) => ({
              type: segment.title_code,
              label: segment.title_label,
              codes: segment.options.map((option) => ({
                code: option.code,
                label: option.label,
                checked: segment.defaultChecked.includes(option.code),
                fixed: segment.defaultChecked.includes(option.code),
              })),
            }))
          );
          registerForm.getValues("segments").forEach((slab, i) => {
            slab.codes.forEach((code, j) => {
              if (
                document.getElementsByName(
                  `segments[${i}].codes[${j}].checked`
                )[0]
              ) {
                document.getElementsByName(
                  `segments[${i}].codes[${j}].checked`
                )[0].checked = code.checked;
                if (!code.fixed)
                  document.getElementsByName(
                    `segments[${i}].codes[${j}].checked`
                  )[0].disabled = false;
              }
            });
          });
        } else if (type === "nominee") {
          setFormData((formData) => ({
            ...formData,
            nominee: data.nominee ? "Yes" : "No",
            nominee_name:
              data.nominee && !isEmpty(data.nominees)
                ? data.nominees[0].name
                : "",
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const segmentReselection = () => {
    const body = {};
    body.details = registerForm
      .getValues("segments")
      .map((segment) => ({
        segment_group_code: segment.type,
        selected_options: segment.codes
          .filter((c) => c.checked)
          .map((c) => c.code),
      }))
      .filter((s) => !isEmpty(s.selected_options));
    LeadService.getRekycPaymentSummary(body)
      .then((data) => {
        if (data.success) {
          setShowTNC(
            registerForm.getValues("segments").filter((segment) => {
              return (
                segment.codes.filter((c) => c.checked && !c.fixed).length > 0
              );
            }).length > 0 && data.total > 0
          );
          setTotal(data.total);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setDisableContinue(showTNC && !watchSegmentConfirmation);
  }, [showTNC, watchSegmentConfirmation]);

  const onSubmit = (requestData) => {
    setHasChanges(true);
    setLoading(true);
    const body = {};
    body.details = requestData.segments
      .map((segment) => ({
        segment_group_code: segment.type,
        selected_options: segment.codes
          .filter((c) => c.checked)
          .map((c) => c.code),
      }))
      .filter((s) => !isEmpty(s.selected_options));
    let p = null;
    if (
      body.details
        .map((segment) => segment.selected_options.length)
        .reduce((sum, next) => sum + parseFloat(next || 0), 0) === 0
    ) {
      p = Promise.resolve({ success: true });
    } else {
      p = LeadService.updateSegments(body);
    }
    p.then((data) => {
      const getGender = (gender) => {
        switch (gender) {
          case "Male":
            return "M";
          case "Female":
            return "F";
          case "Other":
            return "O";
          default:
            break;
        }
        return gender;
      };
      const getMaritalStatus = (marital) => {
        switch (marital) {
          case "Single":
            return "S";
          case "Married":
            return "M";
          case "Others":
            return "O";
          default:
            break;
        }
        return marital;
      };
      if (data.success) {
        let details = {};
        if (enableFields.includes("aadhaar"))
          details.aadhaar = requestData.aadhaar;
        if (showFields.includes("dob") && enableFields.includes("dob"))
          details.dob = requestData.dob;
        if (
          showFields.includes("education") &&
          enableFields.includes("education")
        )
          details.education = requestData.education;
        if (
          showFields.includes("occupation") &&
          enableFields.includes("occupation")
        )
          details.occupation = requestData.occupation;
        if (
          showFields.includes("annual_income") &&
          enableFields.includes("annual_income")
        )
          details.annual_income = requestData.annual_income;
        if (showFields.includes("gender") && enableFields.includes("gender"))
          details.gender = getGender(requestData.gender);
        if (
          showFields.includes("marital_status") &&
          enableFields.includes("marital_status")
        )
          details.marital_status = getMaritalStatus(requestData.marital_status);
        if (
          showFields.includes("mother_name") &&
          enableFields.includes("mother_name")
        )
          details.mother_name = requestData.mother_name;
        if (
          showFields.includes("father_name") &&
          enableFields.includes("father_name")
        )
          details.father_name = requestData.father_name;
        return LeadService.updateRekycLeadDetails({ details });
      } else {
        console.error(data.error);
        setApiMessage(data.error);
        return Promise.reject(data);
      }
    })
      .then((data) => {
        if (data.success) {
          history.push(getPath(data.next_step));
        } else {
          if (data.invalid_fields) {
            onError(
              data.invalid_fields.reduce(
                (acc, curr) => ((acc[curr.field] = true), acc),
                {}
              )
            );
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          if (data.error !== "Invalid Input Value") setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const markAsPrimaryBank = (bank_id) => (checked) => {
    showConfirmDialog(
      <>
        <div className="mark-primary">{strings.confirm_mark_as_primary}</div>
        <SingleCheckbox
          name="mark_as_dp_dialog"
          classes="markbank checkbox-colour"
          inputClass="segchks1"
          labelClass="segchks2"
          label={strings.mark_as_primary_in_dp}
          defaultChecked={true}
        />
      </>,
      undefined,
      () => {
        const dpCheckBox = document.getElementById("mark_as_dp_dialog");
        setLoading(true);
        LeadService.rekycMarkBankAsPrimary({
          bank_id,
          is_dp_primary: dpCheckBox.checked,
        })
          .then((data) => {
            if (data.success) {
              revertSection("bank");
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setLoading(false));
        closeConfirmDialog();
      },
      strings.yes,
      strings.no
    );
  };

  const onDeleteNominee = () => {
    showConfirmDialog(
      strings.remove_nominee_msg,
      undefined,
      () => {
        setLoading(true);
        let body = {
          details: {
            nominee: false,
            nominees: [],
          },
        };
        LeadService.rekycNomineeDetails(body)
          .then((data) => {
            if (data.success) {
              revertSection("nominee");
            } else {
              setApiMessage(data.error);
              console.error(data.error);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => setLoading(false));
        closeConfirmDialog();
      },
      strings.yes,
      strings.no
    );
  };

  const onError = (data) => {
    if (
      data.gender ||
      data.marital_status ||
      data.dob ||
      data.mother_name ||
      data.father_name
    ) {
      var section1 = document.querySelector(".summary_section");
      section1.scrollIntoView({
        behavior: "smooth",
      });
    } else if (data.aadhaar) {
      var section2 = document.querySelector(".summary_section2");
      section2.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <SummaryProvider>
      <LayoutWrapper>
        <div className="summarydiv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 marg">
                <Form
                  autoFocusElement="mobile"
                  reactHookForm={registerForm}
                  onSubmit={onSubmit}
                  onError={onError}
                >
                  <div className="forms">
                    <div className="col-md-12">
                      <h5 className="pb-2 head5">
                        {strings.hi}
                        {formData.name}
                      </h5>
                      <p>{strings.rekyc_verify_label}</p>
                      <h6 className="plds summary_section">Personal details</h6>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="flex align-items-center mb-3 mt-3">
                            <label className="p-0 col-md-3 mb-0">Mobile</label>
                            <div className="row ml-0 col-md-6">
                              <div className="disabled color-gray">
                                {formData.mobile && mask(formData.mobile)}
                              </div>
                              <>
                                <IconButton
                                  tooltip={strings.edit_icon}
                                  classes="mobile-edit"
                                  imgsrc={
                                    process.env.PUBLIC_URL + "/img/pen.png"
                                  }
                                  imgclass="pimg"
                                  click={() => setShowMobile(true)}
                                />
                                {showRevert.mobile ? (
                                  <IconButton
                                    tooltip={strings.revert_icon}
                                    classes="mobile-undo"
                                    imgsrc={
                                      process.env.PUBLIC_URL + "/img/undo.png"
                                    }
                                    imgclass="uimg"
                                    click={onRevert("mobile")}
                                  />
                                ) : null}
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="flex align-items-center mb-3 mt-3">
                            <label className="p-0 col-md-3 mb-0 mt-sm-24">
                              E-mail
                            </label>
                            <div className="row ml-0 col-md-9">
                              <div className="disable color-gray">
                                {formData.email &&
                                  maskEmail(formData.email, "*", 2, 2)}
                              </div>
                              <>
                                <IconButton
                                  tooltip={strings.edit_icon}
                                  classes="email-edit"
                                  imgsrc={
                                    process.env.PUBLIC_URL + "/img/pen.png"
                                  }
                                  imgclass="pimg"
                                  click={() => setShowEmail(true)}
                                />
                                {showRevert.email ? (
                                  <IconButton
                                    tooltip={strings.revert_icon}
                                    classes="email-undo"
                                    imgsrc={
                                      process.env.PUBLIC_URL + "/img/undo.png"
                                    }
                                    imgclass="uimg"
                                    click={onRevert("email")}
                                  />
                                ) : null}
                              </>
                            </div>
                          </div>
                        </div>
                        {showFields.includes("gender") ? (
                          <div className="col-md-6">
                            <RadioGroup
                              mainClass="row ml-0 pt-2 pb-3"
                              tabIndex="3"
                              mainLabelClass="p-0 col-md-3"
                              mainLabel={strings.rekyc_gender_label}
                              options={RadioGroupOptions.GENDER}
                              name="gender"
                              disabled={!enableFields.includes("gender")}
                              errorClass="text-danger errorfont radio_error ml-11"
                            />
                          </div>
                        ) : null}
                        {showFields.includes("marital_status") ? (
                          <div className="col-md-6">
                            <RadioGroup
                              mainClass="row ml-0 pt-2 pb-3"
                              tabIndex="4"
                              mainLabel={strings.rekyc_marital_label}
                              mainLabelClass="p-0 col-md-3 radio-label"
                              options={RadioGroupOptions.MARITAL_STATUS}
                              name="marital_status"
                              disabled={
                                !enableFields.includes("marital_status")
                              }
                              errorClass="text-danger errorfont radio_error ml-11"
                            />
                          </div>
                        ) : null}
                        {showFields.includes("dob") ? (
                          <div className="col-md-6">
                            <div className="flex align-items-center mb-2">
                              <label className="mname_label p-0 col-md-3 pt-2">
                                Date of Birth
                              </label>
                              <Input
                                mainclass="col-md-6 pt-2"
                                classes="inp"
                                tabIndex="5"
                                inputClass="mninp"
                                type="date"
                                name="dob"
                                max={moment()
                                  .subtract(18, "years")
                                  .format(format_without_time)}
                                maxLength={10}
                                disabled={!enableFields.includes("dob")}
                                errorClass="text-danger errorfont mnerr"
                              />
                            </div>
                          </div>
                        ) : null}
                        {showFields.includes("annual_income") ? (
                          <div className="col-md-6">
                            <Dropdown
                              tabIndex="6"
                              label={strings.rekyc_income_label}
                              name="annual_income"
                              options={ddIncome}
                              classes="flex align-items-center"
                              labelClass="col-md-3 pt-2 p-0"
                              selectClass="col-md-6 inselect"
                              disabled={!enableFields.includes("annual_income")}
                              errorclass="text-danger errorfont"
                            />
                          </div>
                        ) : null}
                        {showFields.includes("occupation") ? (
                          <div className="col-md-6">
                            <Dropdown
                              tabIndex="7"
                              label={strings.rekyc_occupation_label}
                              name="occupation"
                              disabled={!enableFields.includes("occupation")}
                              options={ddOccupation}
                              classes="flex align-items-center"
                              labelClass="col-md-3 p-0 pt-2"
                              selectClass="col-md-6 inselect"
                              errorclass="text-danger errorfont"
                            />
                          </div>
                        ) : null}
                        {showFields.includes("education") ? (
                          <div className="col-md-6">
                            <Dropdown
                              tabIndex="8"
                              label={strings.rekyc_education_label}
                              name="education"
                              disabled={!enableFields.includes("education")}
                              options={ddEducation}
                              classes="flex align-items-center"
                              labelClass="col-md-3 p-0 pt-2"
                              selectClass="col-md-6 inselect edlbl"
                              errorclass="text-danger errorfont mh-18"
                            />
                          </div>
                        ) : null}
                        {showFields.includes("mother_name") ? (
                          <div className="col-md-6">
                            <div className="flex align-items-center mb-2">
                              <label className="mname_label col-md-3 p-0 tp-1 pt-2">
                                Mother's Name
                              </label>
                              <Input
                                mainclass="col-md-6"
                                classes="inp"
                                tabIndex="9"
                                inputClass="mninp"
                                type="text"
                                name="mother_name"
                                maxLength={50}
                                disabled={!enableFields.includes("mother_name")}
                                errorClass="text-danger errorfont mnerr"
                              />
                            </div>
                          </div>
                        ) : null}
                        {showFields.includes("father_name") ? (
                          <div className="col-md-6">
                            <div className="flex align-items-center mb-2">
                              <label className="p-0 col-md-3  pt-2">
                                Father's Name
                              </label>
                              <Input
                                mainclass="col-md-6"
                                classes="inp"
                                tabIndex="10"
                                inputClass="mninp"
                                type="text"
                                name="father_name"
                                maxLength={50}
                                disabled={!enableFields.includes("father_name")}
                                errorClass="text-danger errorfont mnerr"
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="formz summary_section2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="flexz">
                          <div className="col-md-2">
                            <label className="pt-4">Aadhaar</label>
                          </div>
                          <div className="flexx col-md-12 aad">
                            <span className="aax1 mt-4 ml-5">XXXX</span>
                            <span className="aax1 mt-4">XXXX</span>
                            <span className="mt-4">
                              <Input
                                mainclass="col-md-12"
                                tabIndex="11"
                                inputClass="disble"
                                type="number"
                                pattern="[0-9.]+"
                                maxLength={4}
                                name="aadhaar"
                                disabled={!enableFields.includes("aadhaar")}
                                errorClass="text-danger errorfont aadhaarerr"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="flexz align-items-center mt-4">
                          <div className="p-0 col-md-3">
                            <label className="pan-label">PAN</label>
                          </div>
                          <div className="flex col-md-10 pan-input color-gray">
                            {formData.pan && mask(formData.pan)}
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="forms">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <h6 className="plds">Address details</h6>
                            <br />
                            <div className="row ml-0">
                              <h6 className="head6">Correspondence address</h6>
                              <IconButton
                                tooltip={strings.edit_icon}
                                classes={"add-edit"}
                                imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                                imgclass="pimg"
                                click={() => setShowAddress(true)}
                              />
                              {showRevert.current_address ? (
                                <IconButton
                                  tooltip={strings.revert_icon}
                                  classes={"add-undo"}
                                  imgsrc={
                                    process.env.PUBLIC_URL + "/img/undo.png"
                                  }
                                  imgclass="uimg"
                                  click={onRevert("current_address")}
                                />
                              ) : null}
                            </div>
                            <p className="add-color">{formData.current_addr}</p>
                            <h6 className="head6">Permanent address</h6>
                            <p className="add-color">
                              {formData.permanent_addr}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formz bankoverflow">
                    <div className="col-md-12">
                      <div className="row pt-4 pb-4">
                        <div className="col-md-12">
                          <h6 className="plds pb-3 row">
                            <div>Bank details</div>
                            {showRevert.bank ? (
                              <IconButton
                                tooltip={strings.revert_icon}
                                classes={"bank-undo"}
                                imgsrc={
                                  process.env.PUBLIC_URL + "/img/undo.png"
                                }
                                imgclass="uimg"
                                click={onRevert("bank")}
                              />
                            ) : null}
                          </h6>
                          <div className="flex row p-0">
                            {!isEmpty(formData.banks)
                              ? formData.banks.map((bank) => (
                                  <div
                                    key={bank.bank_id}
                                    className="card bcls h-178 w-280 m-1"
                                  >
                                    <div className="container-fluid">
                                      <SingleCheckbox
                                        name="primary"
                                        tooltip={
                                          bank.is_primary
                                            ? strings.primary_account
                                            : strings.mark_primary
                                        }
                                        classes="bankchk pl-1 primary-checkbox"
                                        inputClass="bankcheck"
                                        checked={bank.is_primary}
                                        onChange={
                                          !bank.is_primary
                                            ? markAsPrimaryBank(bank.bank_id)
                                            : null
                                        }
                                      />
                                      <h6 className="bankname pt-2 pb-3">
                                        {bank.bank_name}
                                      </h6>
                                      <h5 className="pt-2 pb-3 head3">
                                        {bank.bank_account_no}
                                      </h5>
                                      <p className="pt-1">{bank.bank_ifsc}</p>
                                    </div>
                                  </div>
                                ))
                              : null}
                            {!isEmpty(formData.banks) &&
                            formData.banks.length < formData.maxAllowedBanks ? (
                              <div
                                className="card bcls w-280 h-178 m-1"
                                onClick={() => setShowBank(true)}
                              >
                                <div className="container-fluid">
                                  <IconButton
                                    imgsrc={
                                      process.env.PUBLIC_URL +
                                      "/img/add_icon.png"
                                    }
                                    imgclass="addicon"
                                    click={() => setShowBank(true)}
                                  />
                                  <p className="addbank">Add Bank Account</p>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="forms">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="plds row">
                            <div>Nominee details</div>
                            <IconButton
                              tooltip={strings.edit_icon}
                              classes={"nom-edit"}
                              imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                              imgclass="pimgs"
                              click={() => setShowNominee(true)}
                            />
                            {formData.nominee === "Yes" ? (
                              <IconButton
                                tooltip={strings.delete_icon}
                                classes={"nom-del"}
                                imgsrc={
                                  process.env.PUBLIC_URL + "/img/delete.png"
                                }
                                imgclass="dimg"
                                click={onDeleteNominee}
                              />
                            ) : null}
                            {showRevert.nominee ? (
                              <IconButton
                                tooltip={strings.revert_icon}
                                classes={"nom-undo"}
                                imgsrc={
                                  process.env.PUBLIC_URL + "/img/undo.png"
                                }
                                imgclass="uimg"
                                click={onRevert("nominee")}
                              />
                            ) : null}
                          </h6>
                          {formData.nominee === "Yes" ? (
                            <div className="flexs pt-4">
                              <label className="nom-name pr-3">Nominee 1</label>
                              <div className="color-gray">
                                {formData.nominee_name}
                              </div>
                            </div>
                          ) : (
                            <p className="add-color pt-2 no-nom">No Nominees</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="formz">
                    <div className="col-md-12">
                      <div className="row pt-4 pb-4">
                        <div className="col-md-12  ">
                          <h6 className="plds pb-4 row">
                            <div>Add segment</div>
                            {showRevert.segment ? (
                              <IconButton
                                tooltip={strings.revert_icon}
                                classes={"segment-undo pl-3"}
                                imgsrc={
                                  process.env.PUBLIC_URL + "/img/undo.png"
                                }
                                imgclass="uimg"
                                click={onRevert("segment")}
                              />
                            ) : null}
                          </h6>
                          <div className="row">
                            <div className="flex segflex">
                              {segments.map((segment, index) => (
                                <SegmentSlab
                                  key={index}
                                  label={segment.title_label || ""}
                                  mainclass="select-plan segment1 col-md-12"
                                  code={segment.title_code}
                                  subclass={
                                    !isEmpty(segment.defaultChecked)
                                      ? "seghlbl"
                                      : "seghlbl seghlbl-active"
                                  }
                                  type="checkbox"
                                  labelClass="seghchk"
                                  options={segment.options}
                                  defaultchecked={segment.defaultChecked}
                                  rupeclass="ruppie rupees"
                                  rupee={segment.amount || null}
                                  rupeelive={segment.msp || null}
                                  checksubclass="show-inp segmentcheck"
                                  checkclass="segment_mobile"
                                  parentNode="segments"
                                  index={index}
                                  name="codes"
                                  handleChange={segmentReselection}
                                  disableOnChange={
                                    showRevert.segment ||
                                    !isEmpty(segment.defaultChecked)
                                  }
                                  disabled={showRevert.segment}
                                />
                              ))}
                            </div>
                            {showTNC ? (
                              <div className="col-md-12">
                                <Checkbox
                                  type="checkbox"
                                  classes="checkbox-colour"
                                  inputClass="segchks1"
                                  name="segmentConfirmaton"
                                  tabIndex="12"
                                  label={
                                    <>
                                      {strings.rekyc_segment_label_1}
                                      <b>{total}</b>
                                      {strings.rekyc_segment_label_2}
                                    </>
                                  }
                                  labelClass="segchks2"
                                  errorclass="text-danger errs"
                                />
                              </div>
                            ) : null}
                          </div>
                          {apiMessage ? (
                            <div className="text-danger text-center mt-4">
                              <label id="txterror" name="txterror">
                                {apiMessage}
                              </label>
                            </div>
                          ) : null}
                          <br />
                          <Button
                            disabled={disableContinue}
                            type="submit"
                            classes1={
                              disableContinue ? "discontinue" : "inps mt-0"
                            }
                            label="Continue"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Mobile
            show={showMobile}
            cancelButtonAction={() => setShowMobile(false)}
            onSuccess={() => {
              setShowMobile(false);
              setHasChanges(true);
              revertSection("mobile");
            }}
          />
          <Email
            show={showEmail}
            cancelButtonAction={() => setShowEmail(false)}
            onSuccess={() => {
              setShowEmail(false);
              setHasChanges(true);
              revertSection("email");
            }}
          />
          <Address
            show={showAddress}
            permanentAddress={{
              flat: formData.permanent_addr1,
              street: formData.permanent_addr2,
              landmark: formData.permanent_addr3,
              pin: formData.permanent_pincode,
              city: formData.permanent_city,
              state: formData.permanent_state,
            }}
            cancelButtonAction={() => setShowAddress(false)}
            onSuccess={() => {
              setShowAddress(false);
              setHasChanges(true);
              revertSection("current_address");
            }}
          />
          <BankDetails
            show={showBank}
            cancelButtonAction={() => setShowBank(false)}
            onSuccess={() => {
              setShowBank(false);
              setHasChanges(true);
              revertSection("bank");
            }}
          />
          {showNominee && (
            <Nominee
              show={showNominee}
              cancelButtonAction={() => setShowNominee(false)}
              maxLimit={formData.maxAllowedNominees}
              onSuccess={() => {
                setShowNominee(false);
                setHasChanges(true);
                revertSection("nominee");
              }}
            />
          )}
        </div>
      </LayoutWrapper>
    </SummaryProvider>
  );
};

export { SummaryPage };
