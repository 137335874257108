/**
 * All strings used in the pages
 */

export const strings = {
  email_header: "Verify your email",
  phone_header: "Let's get started",
  nri_content: "If you are NRI or Corporate, click ",
  password_header: "Create password",
  password_subheader: "Create a password to resume your application anytime.",
  cmn_otp: "Didn't recieve OTP? ",
  resend: "Resend",
  continue: "Continue",
  signin: "Sign in",
  signup: "Open an account",
  cmncontinue: "To continue with the application",
  tariff_plan: "By proceeding, I agree to",
  rekyc_option: "Existing customer? Modify or re-activate your account ",
  donthaveaccount: "Don't have an account? ",
  take_a_break: "To temporary deactivate your trading account, ",
  whatsapp: "WhatsApp",
  signwithgoogle: "Verify with google",
  verify: "Verify",
  otpenter: "Enter OTP",
  enterEmail: "Enter your email ID",
  entermobile: "Enter Mobile Number",
  yes: "Yes",
  no: "No",
  ok: "OK",
  null: "",
  proceed_title: "Proceed",
  //pan
  pantext: "PAN Number",
  panname: "Name as per PAN",
  pan_placeholder: "Enter PAN Number",
  pansubmit: "Confirm",
  prefix_name: "I Confirm I'm ",
  panheading: "Let's start by entering your PAN details",
  calendar_label: "Date of Birth",
  calendar_placeholder: "Enter Date of Birth",
  pan_wrongDetails: "Wrong Details? Click here to re-enter",
  pan_wrongName: "Wrong name? Click here to verify",
  dateofbirth: "Date of birth",
  pan_note:
    "Note: Please confirm your details before proceeding. You will not be able to change it later.",

  //about
  about_head: "Hi",
  personal3_head: "Let us know your preferences",
  about_subhead: "Tell us a bit about yourself",
  nomiee_head: "Nominee",
  nomiee_subhead: "Nominate someone to entrust your funds.",
  about_first_nameholder: "Enter First Name",
  about_middle_nameholder: "Enter Middle Name",
  about_last_nameholder: "Enter Last Name",
  about_first_name: "First Name",
  nominee_name: "Nominee Name",
  nominee_guardian_name: "Guardian Name",
  nominee_mobile_no: "Nominee Mobile Number",
  nominee_percentage_share: "% Share",
  nominee_guardian_mobile_no: "Guardian Mobile Number",
  nominee_relationship: "Relationship With The Applicant",
  nominee_guardian_relationship: "Relationship With The Guardian",
  nominee_email: "Nominee E-mail ID",
  nominee_guardian_email: "Guardian E-mail ID",
  nominee_guardian_note:
    "Please note, you cannot enter your details as the Guardian.",
  about_middle_name: "Middle Name",
  about_last_name: "Last Name",
  about_education_label: "Education Qualification",
  about_occupation_label: "Occupation Type",
  about_income_label: "Annual Income Range",
  enter_password: "Enter Password",
  errormsg: " All fields are required !",
  stay_logged_in: "Stay Logged In",
  inactivity_message:
    "For security reasons you will be automatically logged out in {{seconds}} seconds if no activity continues.",

  //Rekyc
  rekyc_occupation_label: "Occupation ",
  rekyc_education_label: "Qualification ",
  rekyc_income_label: "Annual Income ",
  rekyc_relation_label: "Relationship ",
  rekyc_gender_label: "Gender ",
  rekyc_marital_label: "Marital Status ",
  rekyc_phone_label: "Phone No ",
  rekyc_update_label: "Update details",
  rekyc_mobile_label: "Enter new number",
  rekyc_email_label: "Enter new email",
  rekyc_nominee_label: "Do you wish to add a nominee?",
  rekyc_mobilepara_label:
    "I hereby declare that the mobile number being updated here belongs to me. Further, I authorise Acumen Capital Market India Ltd to use this to send me any information/ alert/ SMS. This change will affect the Trading and Demat account that I hold with Acumen Capital Market India Ltd.",
  rekyc_emailpara_label:
    "I hereby declare that the e-mail ID being updated here belongs to me/my family. Further, I authorise Acumen Capital Market India Ltd to use this to send me any information/ alert/ SMS. This change will affect the Trading and Demat account that I hold with Acumen Capital Market India Ltd.",
  rekyc_verify_label:
    "Please update your information to ensure that you get proper service & communication from our end.",
  rekyc_segment_label_1: "I agree that once the segment is enabled, INR ",
  rekyc_segment_label_2: " will be debited from my ledger.",
  rekyc_error: "This field is required",
  rekyc_minor_error: "Account holder must be at least 18 years old",
  rekyc_major_error: "Must be less than 18 years old",
  rekyc_mobile_error: "Please enter a valid mobile number",
  rekyc_otp_error: "Please enter a valid OTP",
  rekyc_chkerror: "Please accept the T&C",
  rekyc_emailerror: "Please enter a valid email",
  rekyc_pin: "Please enter a valid pincode",
  rekyc_note:
    "Note: If entered manually, please upload the address proof at the end of the process.",
  rekyc_primary: "Do you wish to add this bank as primary",
  rekyc_thankyou_para:
    "Once your details are approved, we will intimate you via SMS",
  rekyc_tips: "NOTE: To know how to enable location/camera, click ",
  rekyc_sms: "To get IPV link via SMS, click ",
  remove_nominee_msg: "Do you wish to remove nominee?",
  rekyc_nominee_name: "Name",
  rekyc_nominee_guardian_name: "Guardian name",
  rekyc_nominee_mobile_no: "Mobile",
  rekyc_nominee_guardian_mobile_no: "Guardian mobile",
  rekyc_nominee_email: "E-mail",
  rekyc_nominee_guardian_email: "Guardian E-mail",
  rekyc_error_msg: "Sorry, you will not be able to proceed further!",
  add_bank: "Add your bank account",
  reverse_penny_drop_head: "Verify via UPI",
  reverse_penny_drop_note:
    "We will deduct ₹1 from your bank account via UPI, which will be refunded within 2-3 days.",
  penny_drop_head: "Verify via account number",
  penny_drop_note:
    "We will deposit ₹1 to your bank account to verify the account number & IFSC code.",
  verify_now: "Verify now",
  recommended: "Recommended",
  primary_bank_note: "The verified bank will become the primary bank.",

  //Tooltip
  edit_icon: "Edit",
  revert_icon: "Undo",
  primary_account: "Primary account",
  mark_primary: "Mark as primary",
  delete_icon: "Delete",

  gender_label: "Gender",
  add_nominee_label: "Do you wish to add a nominee?",
  minor_nominee_label: "Is your nominee above 18 years?",
  marital_status_label: "Marital Status",
  nationality_label: "Nationality",
  nextbtn: "Next",
  father_name: "Father's name (as per PAN)",
  mother_name: "Mother's name",
  enter_mother_name: "Enter Mother's name",
  enter_father_name: "Enter Father's name",
  birth_place: "Place of birth",
  enter_birth_place: "Enter place of birth",
  mandatory: "* Mandatory",
  major_error_message: "* The nominee should be less than 18 years",
  please_enter_otp: "Enter OTP",
  total_share_mimatch: "* Total share % greater than 100",
  total_share_100: "* Total share % should be 100",
  share_greater_than_100: "* Should be less than 100%",
  share_less_than_0: "* Should be greater than 0%",
  total_share_mimatch_alert: "Total share % greater than 100",
  about_investment_details: "Help us to know about your investment details",
  trading_expe: "Trading Experience",
  trading_pattern: "Trading Pattern",
  PEP_label: "Are you a Politically Exposed Person?",
  wish_internet_label: "Wish to avail internet facility?",
  wish_settile_tradeaccount_label: "When do you wish to settle account?",
  mandatory_documents: "Receiving mandatory documents",
  depository_label: "Choose a Depository",
  settlement_label: "Preference for running account settlement",
  register_broker_label: "Are you registered with any other broker ?",
  broker_name: "Name of the broker",
  cient_code: "Client Code",
  sbi_member: "SEBI Number",
  action_last_3years:
    "Any actions initiated relating to securities during the last 3 years?",
  tooltiplabel: "Please enter how you want your name to be in our records",
  //address
  address_header: "Enter Your Address",
  permanent_address: "Permanent Address",
  permanent_address_holder: "Permanent Address",
  //new
  sameaspermaddress: "Same as Permanent Address",
  currentaddress: "Current Address",
  state: "State",
  city: "City",
  pincode: "Pin code",
  temporary_address: "Temporary Address",
  temporary_address_holder: "Temporary Address",
  address_flat: "Flat/ House no/ Building",
  address_street: "Street name",
  address_area: "Area/Locality",
  previous: "Previous Step",
  segment_label: "Segments",
  pattern_label: "Trading pattern",
  //not found
  backhome: "Back to home ",
  //bank
  doyowantedit: "Edit my bank details",
  ifc: "IFSC Code",
  bankaddress: "Bank Address",
  ac_holdername: "Account holder name",
  micr: "MICR",
  bankname: "Bank name",
  buildingno: "Flat/ House no/ Building",
  street: "Street name",
  locality: "Locality",
  bankaddressarea: "Area/Locality",
  pin: "Pincode",
  bankdetail: "Please enter your bank details",
  banksubhead: "Enter the bank details you would like to register with us",
  acctype: "Account Type",
  accno: "Account Number",
  bank_note: "Note: Please make sure you are the primary account holder.",
  pincode_note: "Note: Please enter the branch pincode.",
  add_bank: "Add your bank account",
  reverse_penny_drop_head: "Verify via UPI",
  reverse_penny_drop_note:
    "We will deduct ₹1 from your bank account via UPI, which will be refunded within 2-3 days.",
  penny_drop_head: "Verify via account number",
  penny_drop_note:
    "We will deposit ₹1 to your bank account to verify the account number & IFSC code.",
  verify_now: "Verify now",
  recommended: "Recommended",
  primary_bank_note: "The verified bank will become the primary bank.",
  //relationship
  rel_mainhead: "Relationship",
  rel_subhead:
    "Seems like account is already opened with the phone number or email ID. If you want to open a dependent account, please select your relationship with the existing account holder.",
  upload_dependency_proof:
    "We will require you to upload dependency proof during this process.",
  max_dependency_reached:
    "You have opened maximum number of accounts with this phone number. To create a new account, please use another phone number.",
  get_started: "Get Started",
  relationnote:
    "Note: Please confirm your details before proceeding. You will not be able to change it later.",
  //E-sign
  aadhar: "Aadhaar ",
  moblinked: " Mobile linked",
  esignhead: "Finally, It's time to sign your documents",
  esignaadharmobotp:
    "As your aadhaar and mobile number are linked, you can digitally sign the application form by validating your aadhaar via OTP. You will receive an OTP on your aadhaar linked mobile number.",
  iwillsignin: "I will e-sign",
  aadharmobnotlink: " Mobile not linked",
  notaharcourier:
    "As your aadhaar and mobile number are not linked, you will have to download, sign and courier the application form to our head office.",
  iwillcourierform: "I will courier the form",
  esign_note:
    "Note: Please verify all your details before proceeding. You will not be able to change it later.",
  esign_alert:
    "Please review your application before e-signing. Once e-signed, the details cannot be edited.",
  //signin
  continueyourapplication: "Continue Your Application",
  rekyc_signin_title: "Modify or Re-activate your account",
  closure_signin_title: "Online account closure",
  password: "Password",
  otp: "OTP",
  phnoremail: "Phone number/Email ID",
  clientCodeOrPAN: "Client code/PAN",
  iagree: " I AGREE",
  //ThankYou
  thankyou: "Thank you,",
  thankyoupara1: "Your application has been submitted successfully",
  rekycthankyoupara1: "Your request has been submitted successfully.",
  rekycthankyoupara2:
    "Once approved, we will update your details/re-activate your account within 24 hours.",
  taketime: "We usually take ",
  taketime1A:
    " hours to verify your account. Once verified, you will receive an email with your login credentials for your trading application.",
  thankyoupara2:
    "Meanwhile, please courier us the power of attorney form to head office within 7 days.",
  signin_check_status:
    "You can sign in & check your application status anytime!",
  address: "Address :",
  headoffice: "Head Office Address",
  officeaddress1: "Acumen Capital Market India Ltd,",
  officeaddress2: "S.T Reddiar & Sons, Veekshanam Road,",
  officeaddress3: "Kochi Pin: 682 035",
  gotowebsit: "Go to our website",
  feedback: "Write us a review",
  //Segements
  segementhead: "Segment selection",
  equitymessge:
    "Buy & Sell shares, mutual funds, derivatives, currencies & commodities on NSE & BSE.",
  commoditymessge: "Buy & Sell commodities on MCX & NCDEX",
  endnotesproof:
    "You will be required to upload Income proof, if segments other than equity cash and/or mutual funds are selected.",
  proof_disclaimer: "If yes, you will be required to upload a nominee proof.",
  rekyc_proof_disclaimer: "You will be required to upload a nominee proof.",
  brokerage: "Brokerage",
  segmentbrokeragenote:
    "Note: Please confirm before proceeding. You will be able to add segments only after account opening process is complete.",
  segmentInfo:
    " For easy & flexible purchase, switch & redemption of mutual funds we are opening your account in physical mode. If you wish to pledge mutual funds, please contact our team after the account opening is completed.",
  choose_mf: "Choose mutual funds mode :",
  //UploadProof
  bullet_head: "Please note, while uploading bank statement.",
  list1: "It should be for the last 6 months.",
  list2: "It is not password protected.",
  list3:
    "Name, Account number, IFSC, MICR, Bank logo or name should be mentioned.",
  list4:
    " If not mentioned, please attach a cheque (name printed) or passbook along with the statement.",
  lessthan1mb: "Please make sure the proofs uploaded are less than 1 MB.",
  uploadproof: "Upload Proofs",
  uploadproofdescription: "Note : Please upload clear and colored photos only",
  download: "Download",
  sizefile: "File size less than",
  onlyallowed: " Allowed only",
  format: "format",
  Remove: "Remove",
  current_address_note:
    "If current address is not the same as permanent address, you will be required to upload a proof of current address.",
  //DigiLoacker
  fetch: "Get Name",
  closure_signin_popup_header: "Temporary deactivation",
  name_note_1:
    "Name mismatch error? Enter your PAN number & we will fetch your name as per IT dept. Try using the name fetched.",
  name_note_2:
    "OTP delay or any other technical issues? It could be due to heavy traffic on the DigiLocker site. Please try again after some time.",
  fetchdetails: "Let's start by fetching your details from ",
  digilocker: "DigiLocker",
  content1:
    "To make your onboarding easier, DigiLocker can verify your documents needed for account opening. You don't need a DigiLocker account for this.",
  content2:
    "Your aadhaar must be linked with a mobile number to receive & confirm OTP. If not linked, please enter details manually.",
  content3: " Your data is 100% safe & secure.",
  content4:
    "I confirm that I am sharing my digiLocker details only for completing the account opening process, in compliance with KRA regulations of 2011 & PMLA guidelines of 2012.",
  content5: 'Please use "Connect to DigiLocker" only if the name on your PAN is exactly the same as your Aadhaar. If not, please enter details manually.',
  connect_digilocker: "Connect to DigiLocker",
  digi_manually: "Enter details manually",
  didinote:
    " Note: Please enter your details correctly. You will not be able to change it later.",
  unlock_file_note:
    " Please do not upload password-protected files. To remove the password, click",
  merge_file_note: " To merge images, click ",
  //Inperson
  inpersonhead: "In person verification",
  finish_ipv_error: "Please finish your IPV",
  inpersonsubhead:
    "Verify your identity by recording a video & taking a photo.",
  yourotp: "Your OTP is",
  recordvideo: "Record a video",
  recordvideodesc:
    "For the In-person verification, please record & upload a video reading out the OTP.",
  startvideo: "Start recording video",
  photohead: "Capture a photo.",
  photosubhead:
    "Please allow location & camera access in your browser & phone setting for capturing live photo/video.",
  takeselfi: "Take a selfie",
  tips: "To enable location/camera access in your mobile browser, long press on the browser > app info > permission > Allow camera & location.",
  sms_note:
    "NOTE: Unable to access the camera from your desktop? Get the resume application link on your mobile. Click ",
  // android: "To enable location/camera access in your mobile browser, long press on the browser > app info > permission > Allow camera & location. ",
  ios: "For iOS: Go to settings > Select the browser > Enable camera & location",
  inperson_note_2:
    "Unable to access camera? Resume your application from your mobile. To get link via SMS, click ",
  re_takeselfi: "Retake selfie",
  re_recordvideo: "Retake video",
  //PaymentPage
  payment_head: "Account Opening Charges",
  head_sub: "(You only pay once)",
  total: "Total",
  grand_total: "Grand Total",
  discount_title: "Discount Amount",
  //T & C
  checkoutdetails: "Check out your default settings",
  retry: "Please retry again",
  //error message
  phoneoremailerror: "Please enter valid Mobile number/ Email",
  clientcodePANError: "Please enter valid Client code/PAN",
  passworderror: "Please enter between 4 to 30 characters",
  ivalidifc: "InValid IFC Code.",
  invalidAccno: "InValid Account Number.",
  please_upload: "Please Upload",
  phone_error: "Please enter a valid mobile number",
  processerror: "error occurred in process",
  validpan: "Enter valid Pan",
  alertmessage: "Please upload doc less than 1 MB.",
  alerthead: "Oops!",
  validemail: "Enter valid Email",
  retry_error_message: "Something went wrong. Please try again.",
  // failed_google: "Failed to signin with google",
  failed_google_initialization_failed: "Please enable Cookies on your browser",
  //count
  resendin: "Resend in",
  seconds: "seconds",

  //lead status
  leasstatus_def1:
    "You have already completed your on-boarding process & your application is under review. We usually take ",
  leasstatus_def1A:
    " hours to verify & create your ID. You will receive welcome mail once your application is completed.",
  leasstatus_def2: "If it is causing too much delay (more than ",
  leasstatus_def2_1: " hours), we are extremely sorry! You can call on ",
  leasstatus_def2_a: "+91 ",
  leasstatus_def2_b: " to ask where your application is stuck.",
  leasstatus_def3: "While we are working on it, download our trading app.",
  hi: "Hi ",
  rekyc_leaststatus:
    "Once approved, we will update your details/reactivate your account within 24 hours.",
  closure_status: "Once approved, we will update your closure status.",

  application_status_page_text:
    ", check your application status here. We are excited to have you on board!",
  application_status_page_heading: "Application Status",
  rekyc_application_status_page_text:
    "! You can check your modification/re-activation status here.",
  closure_application_status_page_text:
    "! You can check your account closure status here.",

  //Alert message
  //confirm_msg: "Changes you made may not be saved. Leave Site?",
  confirm_msg: "You have unsaved changes. Do you wish to continue?",
  proceed_msg: "Do you wish to proceed to sign manually?",
  revert_msg: "Do you wish to revert the changes?",
  confirm_remove_nominee_msg: "Confirm remove Nominee?",
  ok_title: "Continue",
  cancel_title: "Cancel",
  alert_head: "Are You Sure !",
  error_aadhar: "Invalid Aadhaar Number",
  please_reupload: "Please Reupload",
  please_retry: "Please Retry",
  colored_msg: "Please upload colored image",

  //lead status
  aadhar_message: "Please enter your Aadhaar number",
  confirm_title: "Confirm",
  close_text: "Close",

  mark_as_primary: "Mark as primary",
  mark_as_primary_in_dp: "Mark as primary in DP",
  confirm_mark_as_primary: "Mark as primary in trading account?",
  confirm_mark_primary: "Mark as primary in trading account",

  // Closure know more
  know_more: "Know More",
  know_more_1a: "To de-activate your account, you can write us a mail at",
  know_more_1b: "kyc@acumengroup.in",
  know_more_1c: "from your registered e-mail ID.",
  know_more_2:
    "Your trading account will be de-activated but your demat Account will be active.",
  know_more_3: "You will not have access to the trading platforms.",
  know_more_4:
    "AMC and other such charges will continue to be levied on the trading ledger, if applicable.",
  know_more_5:
    "You will continue to receive periodic emails like account statements, holding statements, etc.",
  know_more_6:
    "To Freeze demat account, login to your depository (CDSL/NSDL) & freeze/unfreeze.",
  please_note: "Please note",
  closure_note_1:
    "Since your trading account and demat accounts deal with securities, make sure that there are no shares or other forms of securities there. You could sell them out or get them transferred to another demat account. You could also have them rematerialized and store them as physical certificates.",
  closure_note_2:
    "Your account should not reflect a negative cash balance. In such a case, your request to close your trading account would get rejected.",
  closure_reason_title: "Reason for closure (select one or more options)",
  closure_reason_other_title: "Please describe the reason for closure",
  closure_holdings_title:
    "We see you are holding few shares with us in your demat account. What would you like to do with it?",
  closure_holdings_information: "Please enter your new account information",
  closure_reason_error: "Select one or more reasons",
  closure_thankyou_feedback: "Meanwhile, please give us your feedback.",
  share_feedback: "Share Feedback",
};

export const config = {
  google: {
    client_id: "",
    secret_id: "",
  },
};
